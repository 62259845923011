var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('a-skeleton',{attrs:{"paragraph":{ rows: 4 }}}):_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header pt-3 pb-3"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center",class:{ 'order-1 flex-row-reverse': _vm.$i18n.locale === 'ar-AR' }},[_c('a-badge',{class:_vm.$i18n.locale === 'ar-AR' ? 'ml-2' : 'mr-2',attrs:{"count":_vm.filteredNotifications.length,"number-style":{
              backgroundColor: 'transparent',
              color: '#999',
              boxShadow: '0 0 0 1px #d9d9d9 inset',
            },"overflowCount":999}}),_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("topBar.notification.notifications")))])],1),_c('div',{staticClass:"d-flex align-items-center",class:{ 'order-0 flex-row-reverse': _vm.$i18n.locale === 'ar-AR' }},[_c('div',{style:(( _obj = {
              width: '350px'
            }, _obj[_vm.$i18n.locale === 'ar-AR' ? 'margin-left' : 'margin-right'] = '10px', _obj ))},[_c('a-input-search',{attrs:{"placeholder":_vm.$t('topBar.notification.searchPlaceholder')},on:{"change":_vm.handleSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('a-button',{on:{"click":_vm.toggleSort}},[_c('a-icon',{attrs:{"type":_vm.sortAscending ? 'sort-ascending' : 'sort-descending'}})],1)],1)])]),_c('div',{staticClass:"p-5"},[(_vm.filteredNotifications.length > 0)?[_vm._l((_vm.paginatedNotifications),function(item,index){return _c('div',{key:index,staticClass:"card",class:{ 'cursor-pointer': _vm.getNotificationLink(item) !== '/' },on:{"click":function($event){return _vm.handleNotificationClick(item)}}},[_c('div',{staticClass:"card-content p-4"},[_c('div',{staticClass:"d-flex align-items-center mb-3 justify-content-end"},[_c('div',{staticClass:"d-flex flex-column text-right"},[_c('div',{staticClass:"d-flex align-items-center justify-content-end mb-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(item.topic))])]),_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.moment(item.createdAt).format("HH:mm DD/MM/YYYY"))+" ")])]),_c('a-avatar',{staticClass:"ml-3",staticStyle:{"background-color":"#969696"},attrs:{"size":"small","icon":"bell"}})],1),_c('p',{staticClass:"mb-0 text-right",attrs:{"dir":"rtl"}},[_vm._v(_vm._s(item.message))])])])}),_c('div',{staticClass:"text-center mt-4"},[_c('a-pagination',{attrs:{"total":_vm.total,"pageSize":_vm.pageSize},on:{"change":_vm.handlePageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]:[_c('div',{staticClass:"text-center p-4"},[_c('a-empty',{attrs:{"description":_vm.$t('topBar.notification.noNotifications')}})],1)]],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }